<template>
  <v-container class="pezzoOperatore px-6 pt-0 z10" fluid>
    <v-row>
      <v-col cols="12" sm="7">
        <v-row class="pa-3">
          <v-col cols="2" class="text-typo pa-0">
            <v-btn
              elevation="0"
              :ripple="false"
              @click="$router.go(-1)"
              icon
              fab
              class="font-weight-600 text-capitalize"
              color="grey"
              ><v-icon x-large>fas fa-arrow-circle-left</v-icon></v-btn
            >
          </v-col>
          <v-col cols="10" class="text-typo pa-0 mt-2">
            <p class="font-weight-600 text-h5 text-typo mb-0">
              {{ progetto["nome"] }} - {{ progetto["codice_commessa"] }}
            </p>
            <p class="font-weight-600 text-h3 text-typo mb-0">
              {{ particolare["nome"] }} - {{ particolare["part_number"] }}
              <span class="float-right" v-if="ruolo === 'Amministratore'">
                <v-btn
                  @click="deletePezzo()"
                  elevation="0"
                  :ripple="false"
                  height="28"
                  min-width="36"
                  width="36"
                  class="
                    font-weight-600
                    text-capitalize
                    btn-ls btn-danger
                    rounded-sm
                  "
                  color="#f5365c"
                >
                  <v-icon size="12">fas fa-trash</v-icon>
                </v-btn></span
              >
            </p>
          </v-col>
        </v-row>
        <v-card class="card-shadow mb-6" style="margin-bottom: 80px !important">
          <div class="card-border-bottom pa-2">
            <p class="text-h4 text-typo mb-0">
              <span class="font-weight-600">{{ pezzo["seriale"] }}</span>
              <span class="float-right">
                  <span class="text-h6">Chiuso il </span>
                  <span class="font-weight-600 text-h5">{{formatDate(pezzo["data_completamento"])}}</span>
              </span>
            </p>
          </div>
          <v-card-text class="text-primary px-2 pt-2">
            <div class="ml-1 font-weight-600 text-h2">
              <v-chip
                v-if="!isPassoAltro"
                label
                :color="colorePasso(passo['conforme'])"
                class="px-2 mt-n1 ml-n1 mr-2"
                small
              >
                <span class="text-white text-h5">{{ indexActive + 1 }}</span>
              </v-chip>
              <span class="pb-n3"
                >{{ passo["nome"] }}
                <v-btn
                  v-if="isPassoAltro"
                  @click="$refs.fileUpload.click()"
                  elevation="0"
                  :ripple="false"
                  height="28"
                  class="
                    float-right
                    font-weight-600
                    text-capitalize
                    btn-ls
                    text-white
                    rounded-sm
                  "
                  color="grey"
                >
                  Aggiungi Foto
                </v-btn>
              </span>
                <span v-if="passo && passo.image && passo.image.value" style="float: right">
                    <a :href="dominio+'/Generale/'+passo.image.value" target="_blank">
                        Istruzioni
                    </a>
                </span>
            </div>
            <input
              multiple
              v-show="false"
              ref="fileUpload"
              type="file"
              accept="image/png, image/gif, image/jpeg"
              @change="fileChosen"
            />
            <div class="mt-2" v-if="passo['requireImage'] || isPassoAltro">
              <v-container
                fluid
                fill-height
                class="text-center"
                @click="
                  (!pezzo['data_completamento'] || imagesAltroUrl.length === 0 )
                    ? $refs.fileUpload.click()
                    : viewImages()
                "
                style="border: 1px solid LightGrey; border-radius: 5px"
              >
                <v-layout
                  align-center
                  justify-center
                  v-show="
                    !urlImageUploaded && !(isPassoAltro && imagesAltro.length)
                  "
                >
                  <v-flex>
                    <v-icon x-large style="color: LightGrey"
                      >fas fa-upload</v-icon
                    >
                    <p class="mt-2 text-light">
                      Clicca per selezionare o scattare un immagine
                    </p>
                  </v-flex>
                </v-layout>

                <v-carousel
                  v-if="isPassoAltro && imagesAltroUrl.length"
                  height="100%"
                  hide-delimiter-background
                  v-model="activeImageCarouselAltro"
                >
                  <v-carousel-item
                    v-for="(image, i) in imagesAltroUrl"
                    :key="'image_' + i"
                  >
                    <img
                      :src="image"
                      v-if="image"
                      style="max-height: 100%; max-width: 100%"
                    />
                  </v-carousel-item>
                </v-carousel>
                <img
                  :src="urlImageUploaded"
                  v-if="urlImageUploaded && !isPassoAltro"
                  style="max-height: 100%; max-width: 100%"
                />
              </v-container>
            </div>
            <v-row class="mt-3" v-if="isPassoAltro">
              <v-col cols="12" class="pt-0">
                <v-textarea
                  hide-details="auto"
                  outlined
                  auto-grow
                  rows="2"
                  background-color="rgba(255,255,255,.9)"
                  light
                  placeholder="Descrizione"
                  autocomplete="off"
                  v-model="noteAltro"
                  class="
                    font-size-input
                    placeholder-lighter
                    text-color-light
                    input-alternative input-focused-alternative input-icon
                  "
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="5" class="mt-3 z10">
        <v-list>
          <v-subheader class="text-typo font-weight-600">
            Passi
            <v-spacer></v-spacer>
            <v-btn
              @click="confirmAndBack()"
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              width="36"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-danger
                rounded-sm
              "
              :color="
                !pezzo['data_completamento'] && allPassiCompleted
                  ? 'green'
                  : 'grey'
              "
            >
              <v-icon size="18">ni-fat-remove</v-icon>
            </v-btn>
          </v-subheader>
          <v-list-item-group
            v-model="passo"
            color="primary"
            mandatory
            ref="elenco_passi"
            style="
              z-index: 10;
              max-height: 75vh;
              overflow-y: auto;
              -webkit-overflow-scrolling: touch;
            "
          >
            <v-list-item
              v-for="(passo, index) in passi"
              :key="passo['_id']"
              :value="passo"
              :ref="'passo_' + passo['_id']"
            >
              <v-list-item-icon>
                <v-chip
                  v-if="passo['_id'] !== 'altro'"
                  label
                  :color="colorePasso(passo['conforme'])"
                  class="px-2"
                  small
                >
                  <span class="text-white ls-0">{{ index + 1 }}</span>
                </v-chip>
                <v-icon
                  v-if="passo['requireImage']"
                  :color="passo['image_name'] ? 'success' : 'grey'"
                  class="ml-2 mr-n6"
                  >fas fa-camera</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ passo["nome"] }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogImmagini" content-class="top-0" width="90%">
      <img
        :src="urlImageUploaded"
        v-if="urlImageUploaded"
        style="max-height: 100%; max-width: 100%"
      />
      <img
        :src="imagesAltroUrl[activeImageCarouselAltro]"
        v-if="isPassoAltro && imagesAltroUrl[activeImageCarouselAltro]"
        style="max-height: 100%; max-width: 100%"
      />
    </v-dialog>
    <div
      class="floatingDiv"
      v-if="(!isPassoAltro && !pezzo['data_completamento']) || isPassoAltro"
    >
      <v-container class="px-6 pt-0 z1" fluid :style="$vuetify.breakpoint.mdAndUp ? 'padding-left: 80px !important;' : ''">
        <v-row style="z-index: 1">
          <v-col cols="12" sm="7" style="z-index: 1">
            <v-card class="card-shadow">
              <v-card-text class="text-primary px-2 pt-3">
                <BottoniPezzo
                  v-if="passo"
                  :passo="passo"
                  :pezzo="pezzo"
                  :isPassoAltro="isPassoAltro"
                  :imageUploaded="imageUploaded"
                  :isLoading="isLoading"
                  @inviaImmagine="inviaImmagine()"
                  @updateCheckPasso="updateCheckPasso"
                  @nextPasso="nextPasso()"
                  @salvaAltro="salvaAltro()"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-container>
</template>

<style>
body,
html {
  height: 100%;
  margin: 0;
}
</style>

<script>
import apiProgetti from "@/js/pages/progetti";
import apiPezzi from "@/js/pages/pezzi";
import { momentDate } from "@/js/formatDate";
const BottoniPezzo = () => import("@/components/BottoniPezzo");

export default {
  name: "pezzoOperatore",
  data() {
    return {
      pezzi: [],
      token: this.$cookies.get("token_goldencar"),
      ruolo: this.$store.state.ruolo,
      id: this.$route.query._id,
      progetto: {},
      particolare: {},
      pezzo: {},
      passi: [],
      passo: {},
      isLoading: false,
      imageUploaded: undefined,
      urlImageUploaded: undefined,
      imagesAltro: [],
      imagesAltroUrl: [],
      noteAltro: "",
      dialogImmagini: false,
      activeImageCarouselAltro: undefined,
      dominio: process.env.VUE_APP_ENDPOINT_API
    };
  },
  components: {
    BottoniPezzo,
  },
  computed: {
    indexActive() {
      return this.passo ? this.passo["sort"] : 0;
    },
    numberOfPages() {
      return Math.ceil(this.particolari.length / this.itemsPerPage);
    },
    isPassoAltro() {
      return this.passo ? this.passo["_id"] === "altro" : false;
    },
    allPassiCompleted() {
      let valid = true;
      if (this.passi && this.passi.length) {
        for (const passo of this.passi) {
          if (
            ((passo["conforme"] === false || passo["conforme"] === undefined) &&
              passo["requireImage"] === false) ||
            (passo["requireImage"] && !passo["image_name"])
          )
            valid = false;
        }
        return valid;
      } else return false;
    },
  },
  watch: {
    passo() {
      if (this.passo && this.passo["image_name"])
        this.urlImageUploaded = process.env.VUE_APP_ENDPOINT_API + "/" + this.passo["image_name"] +'?'+new Date().getTime();
      else this.urlImageUploaded = undefined;
      setTimeout(() => {
        if (
          this.$refs.elenco_passi &&
          this.$refs.elenco_passi.$el &&
          this.$refs["passo_" + this.passo["_id"]] &&
          this.$refs["passo_" + this.passo["_id"]][0] &&
          this.$refs["passo_" + this.passo["_id"]][0].$el
        ) {
          let altezzaDivElenco = this.$refs.elenco_passi.$el.offsetHeight;
          let offsetTopElement =
            this.$refs["passo_" + this.passo["_id"].toString()][0].$el
              .offsetTop;
          if (
            !(
              altezzaDivElenco / 2 >= offsetTopElement &&
              altezzaDivElenco / 2 <= offsetTopElement
            )
          )
            this.$refs.elenco_passi.$el.scrollTo({
              top: offsetTopElement - (altezzaDivElenco / 2 - 60),
              behavior: "smooth",
            });
        }
      }, 30);
    },
    async allPassiCompleted() {
      /*if (
        !this.pezzo["data_completamento"] &&
        this.allPassiCompleted &&
        confirm("Hai completato tutti i passaggi del pezzi, vuoi chiuderlo?")
      ) {
        let valoree = await apiPezzi.closeOne(this.token, { _id: this.id });
        if (valoree) this.$router.go(-1);
      }*/
    },
    async imagesAltro() {
      this.imagesAltroUrl = [];
      if (this.imagesAltro && this.imagesAltro.length) {
        [...this.imagesAltro].forEach(async (x) => {
          this.imagesAltroUrl.push(await this.createObjectUrl(x));
        });
      }
    },
  },
  async created() {
    this.Socket.emit("vistaPezzo", this.id);
    this.Socket.on("resendVistaAperta", () => {
      this.Socket.emit("checkVistaPezzo", this.id);
    });
    this.progetto = await apiProgetti.getOneFromPezzo(this.token, this.id);
    this.particolare = this.progetto['particolari'];
    await this.reloadLista(true);
  },
  methods: {
    formatDate(date) {
      if (date) return momentDate(date);
      return "";
    },
    async reloadLista(firstLoad = false) {
      this.pezzo = await apiPezzi.getOne(this.token, this.id);

      this.pezzo["passi"].push({
        _id: "altro",
        conforme: true,
        nome: "Altro",
        images: this.pezzo["images"],
        note: this.pezzo["note"],
      });
      this.imagesAltro = this.pezzo["images"] || [];
      this.passi = this.pezzo["passi"] || [];
      this.imageUploaded = undefined;
      this.noteAltro = this.pezzo["note"] || "";

      if (firstLoad)
        this.passo = this.passi.find((x) => x["conforme"] === undefined) || {};
    },
    async updateCheckPasso(tipo) {
      const this_id_pezzo = this.pezzo["_id"];
      const this_id_passo = this.passo["_id"];
      this.nextPasso();
      await apiPezzi.updateOnePasso(
        this.token,
        this_id_pezzo,
        this_id_passo,
        tipo,
        this.$store.state.id_utente
      );
      await this.reloadLista(false);
    },
    async inviaImmagine() {
      this.isLoading = true;
      if (this.imageUploaded) {
        new Promise(async (resolve, reject) => {
          let formData = new FormData();
          formData.append("immagine", this.imageUploaded);
          if (
            !(await apiPezzi.sendFile(
              this.token,
              formData,
              this.pezzo["_id"],
              this.passo["_id"],
              this.progetto['codice_commessa'],
              this.particolare['part_number'],
            ))
          ) {
            resolve();
            this.imageUploaded = false;
            this.isLoading = false;
            alert(
              "Errori durante il caricamento dell'immagine precendente, riprovare."
            );
          } else {
            await this.reloadLista(false);
            this.nextPasso();
            this.imageUploaded = false;
            this.isLoading = false;
            resolve();
          }
        });
      }
    },
    colorePasso(conforme) {
      let colore = "grey";
      if (conforme === true) colore = "success";
      else if (conforme === false) colore = "error";
      return colore;
    },
    nextPasso() {
      let nextItem =
        this.passi[
          this.passi.findIndex((x) => x["_id"] == this.passo["_id"]) + 1
        ];
      if (nextItem) this.passo = nextItem;
      //scroll lista scrollTop
    },
    fileChosen(e) {
      if (!this.isPassoAltro) {
        const file = e.target.files[0];
        let those = this;
        var fr = new FileReader();
        fr.onload = function (e2) {
          those.urlImageUploaded = e2.target.result;
        };
        fr.readAsDataURL(file);
        this.imageUploaded = file;
      } else {
        let tempAltroImages = this.imagesAltro;
        for (const file of e.target.files)
          tempAltroImages.push(file);
        this.imagesAltro = tempAltroImages;
      }
    },
    async createObjectUrl(image) {
      if (
        typeof image !== "object" &&
        image &&
        image.substr(image.lastIndexOf("."), 5) === ".webp"
      )
        return process.env.VUE_APP_ENDPOINT_API + "/" + image;
      else if (typeof image === "object") {
        return await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = function (e2) {
            resolve(e2.target.result);
          };
          reader.onerror = function () {
            resolve("");
          };
          reader.readAsDataURL(image);
        });
      } else return "";
    },
    async salvaAltro() {
      const this_id_pezzo = this.pezzo["_id"];
      const imagesAltro = this.imagesAltro;
      const noteAltro = this.noteAltro;
      const token = this.token;
      new Promise(async (resolve, reject) => {
        let formData = new FormData();
        for (let immagine of imagesAltro)
          formData.append("immagini[]", immagine);

        apiPezzi.salvaNotePezzo(token, {
          _id: this_id_pezzo,
          note: noteAltro,
        });
        await apiPezzi.sendDataAltro(token, formData, this_id_pezzo);
        resolve();
      });
      this.confirmAndBack(true);
    },
    async deletePezzo() {
      if (confirm("Confermi di voler eliminare questo pezzo?")) {
        await apiPezzi.deleteOne(this.token, { _id: this.pezzo["_id"] });
        this.$router.go(-1);
      }
    },
    async confirmAndBack(goBack = false) {
      if (
        !this.pezzo["data_completamento"] &&
        this.allPassiCompleted &&
        confirm("Hai completato tutti i passaggi del pezzi, vuoi chiuderlo?")
      ) {
        let valoree = await apiPezzi.closeOne(this.token, { _id: this.id });
        if (valoree) this.$router.go(-1);
      } else if (!goBack) this.$router.go(-1);
    },
    viewImages() {
      this.dialogImmagini = true;
    },
  },
  beforeDestroy() {
    this.Socket.removeListener("resendVistaAperta");
    this.Socket.removeAllListeners("resendVistaAperta");
  },
};
</script>